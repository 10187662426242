import { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { API_URL } from '../config';
import { ChatMsgI, ChatMsgsMapI, HistoricalChatMsgI, WebSocketDataI } from '../types/fetch/chatRoom';
import { FeedbackType } from '../types/common';

import { getAccessToken } from '../services/auth-service';
import { DataMsgSubTypeEnum, ResponseStatusEnum, MsgTypeEnum } from '../enums/fetch/chatRoom';
import { isEmpty, get } from 'lodash';
import { SourceType } from '../types/fetch/common';

const useFetchChatRoomWebSockets = () => {
    const [sessionId, setSessionId] = useState<string>('');
    const [sessionName, setSessionName] = useState<string>('New Chat');
    const [filterCollectionIds, setFilterCollectionIds] = useState<string[]>([]);
    const [currentMsgId, setCurrentMsgId] = useState<string>('');
    const [chatMsgsMap, setChatMsgsMap] = useState<ChatMsgsMapI>({});
    const [topQuestions, setTopQuestions] = useState<string[]>([]);
    const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
    const [isLastMsgResponseLoading, setIsLastMsgResponseLoading] = useState<boolean>(false);
    const [isLastMsgResponseNotComplete, setIsLastMsgResponseNotComplete] = useState<boolean>(false);

    const websocket = useRef<WebSocket | null>(null);
    const [isReconnecting, setIsReconnecting] = useState<boolean>(false);
    const reconnectInterval = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        console.log('Component mounted, setting up WebSocket...');
        setIsPageLoading(true);
        setupWebSocket();
        return () => {
            console.log('Component unmounted, cleaning up WebSocket and intervals...');
            if (websocket.current) {
                websocket.current.close();
            }
            if (reconnectInterval.current) {
                clearInterval(reconnectInterval.current);
            }
        };
    }, []);

    // useEffect(() => {
    //     setFilterCollectionIds([]);
    // }, [sessionId]);

    useEffect(() => {
        const pingInterval = setInterval(() => {
            if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
                // Send a ping message
                websocket.current.send(JSON.stringify({ t: "ping", timestamp: Date.now(), sid: sessionId, mid: uuidv4(), m: "ping" }));
            }
        }, 30000); // 30 seconds
        return () => {
            clearInterval(pingInterval);
        };
    }, [sessionId]);

    const setupWebSocket = async (id?: string) => {
        console.log('Setting up WebSocket with sessionId:', id);
        if (websocket.current) {
            console.log('Closing existing WebSocket connection before setting up a new one...');
            websocket.current.close();
        }

        let wsSessionId = id || uuidv4();
        setSessionId(wsSessionId);
        console.log('Generated sessionId:', wsSessionId);

        const accessToken = await getAccessToken();
        const WS_URL = API_URL!.replace("http", "ws");
        websocket.current = new WebSocket(`${WS_URL}/ws/sessions/${wsSessionId}/?token=${accessToken}`);
        console.log('WebSocket URL:', `${WS_URL}/ws/sessions/${wsSessionId}/?token=${accessToken}`);

        websocket.current.onopen = () => {
            console.log('WebSocket connected');
            setIsReconnecting(false);
            if (reconnectInterval.current) {
                clearInterval(reconnectInterval.current);
                reconnectInterval.current = null;
            }
        };

        websocket.current.onmessage = (event) => {
            const data: WebSocketDataI = JSON.parse(event.data);
            console.log('WebSocket message received:', data);
            handleWebSocketMessage(data);
        };

        websocket.current.onclose = () => {
            console.log('WebSocket disconnected');
            handleWebSocketClose();
        };

        websocket.current.onerror = (error) => {
            console.error('WebSocket error:', error);
        };
    };

    const handleWebSocketMessage = (data: WebSocketDataI) => {
        console.log('Handling WebSocket message type:', data.t, 'message:', data);
        if (data.t === MsgTypeEnum.TEXT) {
            updateAnswerRealTime(data);
        } else if (data.t === MsgTypeEnum.CONTEXT) {
            updateContent('answer', data.mid!, data.m);
        } else if (data.t === MsgTypeEnum.ERROR) {
            updateContent('answer', data.mid!, data.m);
            setIsLastMsgResponseNotComplete(false);
        } else if (data.t === MsgTypeEnum.DATA) {
            handleDataMessage(data);
        } else if (data.t === MsgTypeEnum.STATUS) {
            updateStatus(data);
        } else {
            console.warn('Unknown message type:', data.t, 'message:', data);
        }
    };

    const handleDataMessage = (data: WebSocketDataI) => {
        console.log('Handling WebSocket data message type:', data.m, 'message:', data);
        if (data.m === DataMsgSubTypeEnum.TAGS) {
            updateContent('tags', data.mid!, data.d);
        } else if (data.m === DataMsgSubTypeEnum.FUQS) {
            updateContent('followUpQuestions', data.mid!, data.d);
        } else if (data.m === DataMsgSubTypeEnum.SOURCES) {
            updateContent('sources', data.mid!, data.d);
        } else if (data.m === DataMsgSubTypeEnum.TOP_QUESTIONS) {
            setTopQuestions(data.d as string[]);
            setIsPageLoading(false);
        } else if (data.m === DataMsgSubTypeEnum.TITLE) {
            setSessionName(data.d.toString());
        } else {
            console.warn('Unknown data sub-type:', data.m, 'message:', data);
        }
    };

    const handleWebSocketClose = () => {
        console.log('Handling WebSocket close event, isReconnecting:', isReconnecting);
        if (!isReconnecting) {
            setIsReconnecting(true);
            if (reconnectInterval.current) {
                clearInterval(reconnectInterval.current);
            }
            reconnectInterval.current = setInterval(() => {
                console.log('Attempting to reconnect WebSocket...');
                setupWebSocket(sessionId);
            }, 5000); // Try to reconnect every 5 seconds
        }
    };

    const connectToHistoricalSession = async (historicalSessionId: string, historicalSessionName: string, historicalChatMsgs: HistoricalChatMsgI[]) => {
        console.log('Connecting to historical session:', historicalSessionId);
        setIsPageLoading(true);

        if (websocket.current) {
            console.log('Closing existing WebSocket before connecting to historical session...');
            websocket.current.close();
        }

        setSessionId(historicalSessionId);
        setSessionName(historicalSessionName);
        setCurrentMsgId('');
        setChatMsgsMap({});
        setTopQuestions([]);
        setIsLastMsgResponseLoading(false);
        setIsLastMsgResponseNotComplete(false);
        setupWebSocket(historicalSessionId);
        generateChatMsgsMapFromHistoricalChats(historicalSessionId, historicalChatMsgs);
    };

    // Pass collectionIdsSelectedFromOtherPage when sending the first message in the session, if user navigated to oneChat by sending a message from chat input in other page
    const sendMsg = (inputMsg: string, msgType: `${MsgTypeEnum}` = MsgTypeEnum.QUERY, collectionIdsSelectedFromOtherPage?: string[]) => {

        if (collectionIdsSelectedFromOtherPage) {
            setFilterCollectionIds(collectionIdsSelectedFromOtherPage);
        }

        const collectionIds: string[] = collectionIdsSelectedFromOtherPage ? collectionIdsSelectedFromOtherPage : filterCollectionIds;

        console.log('Sending message:', inputMsg);
        if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
            setIsLastMsgResponseLoading(true);
            setIsLastMsgResponseNotComplete(true);
            const msgId = uuidv4();
            setCurrentMsgId(msgId);
            const newMsg: ChatMsgI = {
                messageId: msgId,
                sessionId: sessionId,
                status: ResponseStatusEnum.QUESTION_SUBMITTED,
                content: {
                    question: inputMsg,
                    answer: "",
                }
            };

            setChatMsgsMap(prevMessagesMap => ({
                ...prevMessagesMap,
                [msgId]: newMsg
            }));
            //console.log(JSON.stringify({ sid: sessionId, mid: msgId, m: inputMsg, t: msgType, filter: { collections: collectionIds }}));
            websocket.current!.send(JSON.stringify({ sid: sessionId, mid: msgId, m: inputMsg, t: msgType, filter: { collections: collectionIds } }));
        } else {
            console.error('WebSocket is not open. Unable to send message.');
        }
    };

    const updateAnswerRealTime = (data: WebSocketDataI) => {
        console.log('Updating answer in real-time for message ID:', data.mid);
        setChatMsgsMap(prevMessagesMap => ({
            ...prevMessagesMap,
            [data.mid!]: {
                ...prevMessagesMap[data.mid!],
                content: {
                    ...prevMessagesMap[data.mid!].content,
                    answer: prevMessagesMap[data.mid!].content.answer + data.m
                }
            }
        }));
        setIsLastMsgResponseLoading(false);
    };

    const updateContent = (field: 'answer' | 'sources' | 'tags' | 'followUpQuestions',
        msgId: string,
        data: Array<SourceType> | string[] | string) => {
        console.log(`Updating content field: ${field} for message ID:`, msgId, 'data:', data);
        setChatMsgsMap(prevMessagesMap => ({
            ...prevMessagesMap,
            [msgId]: {
                ...prevMessagesMap[msgId],
                content: {
                    ...prevMessagesMap[msgId].content,
                    [field]: field === 'answer' ? data as string : field === 'sources' ? data as Array<SourceType> : data as string[],
                }
            }
        }));
        setIsLastMsgResponseLoading(false);
    };

    const updateStatus = (data: WebSocketDataI) => {
        console.log('Updating status for message ID:', data.mid, 'with status:', data.m);
        setChatMsgsMap(prevMessagesMap => ({
            ...prevMessagesMap,
            [data.mid!]: {
                ...prevMessagesMap[data.mid!],
                status: data.m
            }
        }));

        if (data.m === ResponseStatusEnum.RESPONSE_COMPLETE) {
            setIsLastMsgResponseNotComplete(false);
        }
    };

    const undoAnyMsgFeedback = (msgId: string) => {
        console.log('Undoing feedback for message ID:', msgId);
        setChatMsgsMap(prevMessagesMap => ({
            ...prevMessagesMap,
            [msgId]: {
                ...prevMessagesMap[msgId],
                feedback: undefined
            }
        }));
        websocket.current!.send(JSON.stringify({ sid: sessionId, mid: msgId, m: DataMsgSubTypeEnum.FEEDBACK, t: MsgTypeEnum.FEEDBACK, feedback: { type: null, message: null } }));
    }

    const updateAnyMsgFeedback = (msgId: string, feedbackType: FeedbackType, feedbackMsg: string) => {
        console.log('Updating feedback for message ID:', msgId, 'with feedbackType:', feedbackType, 'and feedbackMsg:', feedbackMsg);
        setChatMsgsMap(prevMessagesMap => ({
            ...prevMessagesMap,
            [msgId]: {
                ...prevMessagesMap[msgId],
                feedback: {
                    type: feedbackType,
                    msg: feedbackMsg
                }
            }
        }));
        websocket.current!.send(JSON.stringify({ sid: sessionId, mid: msgId, m: DataMsgSubTypeEnum.FEEDBACK, t: MsgTypeEnum.FEEDBACK, feedback: { type: feedbackType, message: feedbackMsg } }));
    };

    const stopGeneratingAnswer = () => {
        console.log('Stopping generation of answer for message ID:', currentMsgId);
        websocket.current!.send(JSON.stringify({ sid: sessionId, mid: currentMsgId, m: DataMsgSubTypeEnum.CANCEL, t: MsgTypeEnum.CANCEL }));
    }

    const generateChatMsgsMapFromHistoricalChats = (historicalSessionId: string, historicalChatMsgs: HistoricalChatMsgI[]) => {
        console.log('Generating chat messages map from historical chats for session ID:', historicalSessionId);
        const newChatMsgsMap = historicalChatMsgs.reduce((accumulator: ChatMsgsMapI, chatMsg: HistoricalChatMsgI) => {
            accumulator[chatMsg.question_id] = {
                messageId: chatMsg.question_id,
                sessionId: historicalSessionId,
                status: ResponseStatusEnum.RESPONSE_COMPLETE,
                feedback: isEmpty(chatMsg.feedback) ? undefined : { type: get(chatMsg, 'feedback.type')!, msg: get(chatMsg, 'feedback.feedback', '') },
                content: {
                    question: chatMsg.question,
                    answer: chatMsg.answer,
                }
            }
            return accumulator;
        }, {} as ChatMsgsMapI);

        setChatMsgsMap(newChatMsgsMap);
    }

    return { sessionId, sessionName, setFilterCollectionIds, connectToHistoricalSession, isPageLoading, isLastMsgResponseLoading, topQuestions, chatMsgsMap, isLastMsgResponseNotComplete, sendMsg, stopGeneratingAnswer, undoAnyMsgFeedback, updateAnyMsgFeedback };

};

export default useFetchChatRoomWebSockets;