import { ROUTE_ECHO_EVENTS, ROUTE_FETCH_CONVERSATION_SUMMARY, ROUTE_FETCH_EVENTS } from "../constants/routes";
import { BreadcrumbPageI } from "../types/ui/breadcrumb";
import { getRouteAfterRemovingPostfix, getRouteAfterRemovingPrefix } from "./routeHelpers";

export const getBreadcrumbPagesWhenNavigateForward = (existingPages: BreadcrumbPageI[] = [], newCurrentPage: { name: string, url: string }) => {
    const newPages: BreadcrumbPageI[] = existingPages.map((page: BreadcrumbPageI) => {
        return {
            ...page,
            current: false
        }
    });

    const currentPage: BreadcrumbPageI = { name: newCurrentPage.name, to: newCurrentPage.url, current: true }

    return [...newPages, currentPage];
};

export const getBreadcrumbPagesWhenNavigateBackward = (existingPages: BreadcrumbPageI[], newCurrentPageArrayIndex: number) => {
    const newPages: BreadcrumbPageI[] = existingPages.slice(0, newCurrentPageArrayIndex + 1);
    newPages[newCurrentPageArrayIndex] = {
        ...newPages[newCurrentPageArrayIndex],
        current: true
    };

    return newPages;
}

export const getBreadcrumbPages = (existingPages: BreadcrumbPageI[], newCurrentPage: { name: string, url: string, breadcrumbArrayIndex: number }) => {
    if (existingPages.length < newCurrentPage.breadcrumbArrayIndex + 1) { // When navigating forward
        return getBreadcrumbPagesWhenNavigateForward(existingPages, { name: newCurrentPage.name, url: newCurrentPage.url });
    } else if (existingPages.length > newCurrentPage.breadcrumbArrayIndex + 1) { // When navigating backward through breadcrumb
        return getBreadcrumbPagesWhenNavigateBackward(existingPages, newCurrentPage.breadcrumbArrayIndex);
    } else { // When page get refreshed
        return existingPages;
    }
}

export const checkIsBreadcrumbEnabledByLocationPathname = (locationPathname: string) => {
return locationPathname.includes(getRouteAfterRemovingPostfix(getRouteAfterRemovingPrefix(ROUTE_ECHO_EVENTS)))
      || locationPathname.includes(getRouteAfterRemovingPostfix(getRouteAfterRemovingPrefix(ROUTE_FETCH_EVENTS)))
      || locationPathname.includes(getRouteAfterRemovingPostfix(getRouteAfterRemovingPrefix(ROUTE_FETCH_CONVERSATION_SUMMARY)))
}