export enum DocumentVerificationStatusEnum {
    NOT_VERIFIED = 0,
    VERIFICATION_REQUESTED = 1,
    VERIFIED = 2
};

export enum DocumentProcessingStatusEnum {
    UPLOADING = 'uploading',
    PROCESSING = 'processing',
    FAILED = 'failed',
    COMPLETE = 'complete'
}

export const KBPageDocumentStatusEnum = {
    ...DocumentProcessingStatusEnum,
    DELETING: 'deleting'
} as const;

export enum DocumentStatusLabelEnum {
    UPLOADING = 'Uploading',
    PROCESSING = 'Processing',
    DELETING = 'Deleting',
    FAILED = 'Failed',
    PUBLISHED = 'Published',
    DRAFT = 'Draft'
}

export enum DocumentTypeEnum {
    FILE = 'file',
    SENSO = 'senso',
    WEB = 'web',
    TIPTAP = 'tiptap'
}