import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import moment from 'moment';
import { subDays } from 'date-fns';
import { SelectedDateRangeI } from '../../../types/ui/dateRangePickerButton';

interface FetchEventsStateI {
    selectedDateRange: SelectedDateRangeI,
    selectedEventSubCategoryName?: string,
    isFromKnowledgeGapReport: boolean
};

const initialState: FetchEventsStateI = {
    selectedDateRange: {
        startDate: moment(subDays(new Date(), 29)).format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
    },
    isFromKnowledgeGapReport: false
};

export const fetchEventsSlice = createSlice({
    name: 'fetch-events',
    initialState,
    reducers: {
        setSelectedDateRange: (state, action: PayloadAction<SelectedDateRangeI>) => {
            state.selectedDateRange = action.payload;
        },
        setSelectedEventSubCategoryName: (state, action: PayloadAction<string>) => {
            state.selectedEventSubCategoryName = action.payload;
        },
        setIsFromKnowledgeGapReport: (state, action: PayloadAction<boolean>) => {
            state.isFromKnowledgeGapReport = action.payload;
        },
        clearFetchEventsState: (state) => {
            state.selectedDateRange = {
                startDate: moment(subDays(new Date(), 29)).format('YYYY-MM-DD'),
                endDate: moment(new Date()).format('YYYY-MM-DD'),
            };
            state.selectedEventSubCategoryName = undefined;
            state.isFromKnowledgeGapReport = false;
        }
    },
})

export const { setSelectedDateRange, setSelectedEventSubCategoryName, setIsFromKnowledgeGapReport, clearFetchEventsState } = fetchEventsSlice.actions;
export default fetchEventsSlice.reducer;